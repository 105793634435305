import {
  OMIO_PARTNER_ID,
  VUELING_PARTNER_ID,
  HOME_TO_GO_PARTNER_ID,
  MAKE_MY_TRIP_PARTNER_ID,
  TOUR_RADAR_PARTNER_ID,
  HOLISTO_PARTNER_ID,
  NORSE_ATLANTIC_AIRWAYS_PARTNER_ID,
  AIRBALTIC_PARTNER_ID,
  SAS_PARTNER_ID,
  ETRAVELI_PARTNER_ID,
} from './partner_id';

export const CG_EXPERIMENT = {
  ANNUAL_TRAVEL_POLICY: {
    EU_COUNTRIES: [
      'AT',
      'BE',
      'CZ',
      'DE',
      'DK',
      'FI',
      'FR',
      'GR',
      'HU',
      'IE',
      'IT',
      'LT',
      'LV',
      'MT',
      'NL',
      'NO',
      'PL',
      'PT',
      'SE',
      'SK',
      'ES',
      'HR',
      'BG',
      'RO',
      'EE',
      'CY',
      'UK',
      'GB',
    ],
    PARTNER_IDS: [
      OMIO_PARTNER_ID,
      VUELING_PARTNER_ID,
      HOME_TO_GO_PARTNER_ID,
      MAKE_MY_TRIP_PARTNER_ID,
      TOUR_RADAR_PARTNER_ID,
      HOLISTO_PARTNER_ID,
      NORSE_ATLANTIC_AIRWAYS_PARTNER_ID,
      AIRBALTIC_PARTNER_ID,
      SAS_PARTNER_ID,
      ETRAVELI_PARTNER_ID,
    ],
  },
};

export const EXPERIMENT_OFFERS_FEATURE_FLAG_NAME = 'offersPaintedDoorPartnerWhiteList';

export const PAYPAL_UPSELL_BANNER_ANALYTICS_ID = 'paypal-upsell-banner';
export const PAYPAL_UPSELL_MODAL_ANALYTICS_ID = 'paypal-modal';
